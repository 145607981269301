.search-input-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
}
.search-input-container.in {
  position: initial;
}
.search-input-container__paper-area {
  width: 100%;
  height: 48px;
  margin-bottom: 0px;
}
.search-input-container__search-button {
  float: right;
  height: 48px;
  color: #fff;
}
