.ProductDetail__Slider {
  width: 100%;
  min-height: 300px;
}
.ProductDetail__Slider img {
  max-height: 300px;
  max-width: 100%;
  margin: auto;
}
.ProductDetail__Slider .slick-dots {
  bottom: 8px;
}

.ProductDetail__Slider__ImageContainer {
  cursor: pointer;
}
.ProductDetail__NoImage {
  height: 300px;
  width: 100%;
  background-image: url("../images/no-image.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ImageContainer__close {
  position: absolute;
  padding: 12px;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.ImageContainer--zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: none;
}
.ImageContainer--zoom .ProductDetail__Slider .slick-dots {
  position: fixed;
}
.ImageContainer--zoom .ProductDetail__Slider .slick-dots li button:before,
.ImageContainer--zoom .ProductDetail__Slider .slick-arrow:before {
  color: #fff;
}
.ImageContainer--zoom img {
  max-width: 100%;
  height: auto;
  max-height: none;
}
.ImageContainer__loader {
  position: absolute;
  z-index: 0;
}
.slick-track {
  display: flex;
  align-items: center;
}
.ImageContainer--zoom .slick-slider {
  touch-action: auto;
}
@media screen and (min-width: 600px) {
  .ImageContainer--zoom img {
    max-height: 100vh;
  }
  .ProductDetail__Slider .slick-arrow {
    z-index: 4;
  }
  .ProductDetail__Slider .slick-arrow.slick-prev {
    left: 0;
  }
  .ProductDetail__Slider .slick-arrow:before {
    color: #333;
  }
  .ProductDetail__Slider .slick-arrow.slick-next {
    right: 0;
  }
  .ProductDetail__Slider .slick-arrow.slick-prev {
    right: 0;
  }
}
