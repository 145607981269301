.Header {
  position: relative;
  height: 128px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.MuiAvatar-root.Header__avatar {
  height: 96px;
  width: 96px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.MuiSkeleton-root.Header__rect--loading {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
